import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterThree from '../common/footer/FooterThree';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from '../elements/timeline/TimelineTwo';
import TabOne from '../elements/tab/TabOne';
import AboutThree from '../elements/about/AboutThree';
import PricingThree from '../elements/pricing/PricingThree';
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import ApplyForm from "../elements/contact/ApplyForm";
var BlogListData = BlogClassicData.slice(0, 3);



const Apply = () => {
    return (
        <>
            <SEO title="Business" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

               
                
                <Separator />

                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Interested in working with Vindex?"
                                        title = "Apply Here"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ApplyForm formStyle="contact-form-1" />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
                <FooterThree />
            </main>
            
        </>
    )
}
export default Apply;