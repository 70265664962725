import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            
            <li><Link to="/home">Startseite</Link></li>
            
            <li><Link to="/about-us">Über uns</Link></li>

            <li><Link to="/service">Dienstleistungen</Link></li>

            <li><Link to="/gallery">Galerie</Link></li>

            <li><Link to="/team">Unser Team</Link></li>

            <li className="has-droupdown"><Link to="/contact">Kontakt</Link></li>
        </ul>
    )
}
export default Nav;
