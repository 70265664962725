import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'team-01',
        name: 'Ndricim Beqiri',
        designation: 'Chief Executive Officer',
        location: 'Fshat i Vjeter, Ferizaj', 
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/cimi.proo'
            },
            {
                icon: <FiInstagram />,
                url: 'https://www.instagram.com/ndriqim.bb'
            },
            {
                icon: <FiLinkedin />,
                url: 'https://www.linkedin.com/in/ndricimb/'
            },
        ]
       
    },
    {
        image: 'team-02',
        name: 'Viol Ramadani',
        designation: 'Chief Financial Officer',
        location: 'Rr. Gjon Serreci, Ferizaj', 
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/profile.php?id=100077083458927'
            },
            {
                icon: <FiLinkedin />,
                url: 'https://www.linkedin.com/in/viol-ramadani-3ba519233/'
            },
        ]
      
    },
    {
        image: 'team-03',
        name: 'Shpend Heta',
        designation: 'Chief Compliance Officer',
        location: 'Berlin, Germany', 
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: '#'
            },
            {
                icon: <FiTwitter />,
                url: '#'
            },
            {
                icon: <FiInstagram />,
                url: '#'
            },
        ]
    },
    // {
    //     image: 'team-04',
    //     name: 'Altina Thaqi',
    //     designation: 'Chief Operating Officer',
    //     location: 'Ferizaj, Kosovo', 
    //     description: '',
    //     socialNetwork: [
    //         {
    //             icon: <FiFacebook />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiTwitter />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiInstagram />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     image: 'team-05',
    //     name: 'Raif Lipovica',
    //     designation: 'Teamleiter',
    //     location: 'Rr. Rexhep Bislimi, Ferizaj', 
    //     description: '',
    //     socialNetwork: [
    //         {
    //             icon: <FiFacebook />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiTwitter />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FiInstagram />,
    //             url: '#'
    //         },
    //     ]
    // },
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
