import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";

const Service = () => {
    return (
        <>

            <SEO title="Services" />
            <Layout>
                <BreadcrumbOne 
                    title="Immer auf der Suche nach einem besseren Weg"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Service"
                />
                <div className="main-content">

                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Was wir für Sie tun können"
                                        title = "Dienstleistungen für Sie."
                                        //description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border"
                                textAlign = "text-start"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    
                </div>

            </Layout>
            
        </>
    )
}

export default Service;
