import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 42.36,
      lng: 21.15
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyC5QP81HT3SMpOte-aQx52q8QD-qVS5z1w" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={42.367957}
            lng={21.152362}
            text="Vindex Group"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;