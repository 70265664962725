import React from 'react';
import { FiPhoneCall, FiUsers, FiTrendingUp, FiRadio, FiGlobe, FiTrello } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiPhoneCall />,
        title: 'Eingehende und ausgehende Anrufe',
        description: 'Unsere Callcenter-Dienste sind in zwei Arten unterteilt: eingehende und ausgehende Anrufe. Unsere Anrufe für Ihr Unternehmen stehen im Mittelpunkt unserer Inbound-Services.'
    },
    {
        icon: <FiUsers />,
        title: 'Kundendienst',
        description: 'Von komplexen Software- und Hardwareproblemen, CRM-Tool, Product-Fulfillment-Tool, Logistik bis hin zu Multi-Channel-Kundenkommunikation umfasst unser Support ein breites Portfolio an maßgeschneiderten technischen Dienstleistungen.'
    },
    {
        icon: <FiTrendingUp />,
        title: 'Leads generieren',
        description: 'Wir können sowohl B2B als auch B2C bedienen. Wir können Geschäftskontakte maximieren und diese nicht bereiten, Interessenten zu aktiven Verkaufsinteressenten entwickeln.'
    },
    {
        icon: <FiRadio />,
        title: 'Kalte E-Mail und Anrufe',
        description: 'Kalte E-Mails und Kaltanrufe sind bei der Prospektion beide für sich genommen effektiv. Der Unterschied zwischen den beiden ist eine Frage der Lautstärke und Bequemlichkeit gegenüber der Direktheit.'
    },
    {
        icon: <FiGlobe />,
        title: 'Web Entwicklung',
        description: 'Wir entwerfen, erstellen, unterstützen und entwickeln Ihr Unternehmen weiter, indem wir intuitive Websites bereitstellen, die zu mehr Conversions führen.'
    },
    {
        icon: <FiTrello />,
        title: 'App-Entwicklung',
        description: 'Wir sind ein führender Anbieter von Cloud-App-Entwicklungsdiensten, die Ihnen dabei helfen, ein verbessertes Kundenerlebnis zu bieten und geschäftliche Agilität zu erreichen'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;