import React , {useState,useMemo} from 'react';
import emailjs from 'emailjs-com';
import { FileUploader } from "react-drag-drop-files";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import '../../assets/css/custom.css'; // Tell webpack that Button.js uses these styles

const fileTypes = ["JPG", "PNG"];
const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}
function ApplyForm({props , formStyle}) {
    const [ result,showresult ] = useState(false);
    const [file, setFile] = useState(null);
    const handleChange = (file) => {
      setFile(file);
    };
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])
  
    const changeHandler = value => {
      setValue(value)
    }
  
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_p4x3hv8', 
            'template_jgfr42f', 
            e.target, 
            'user_jrfTH2e0Ely35ZCVFdT9S'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <label>Name</label>
                <input 
                type="text"
                name="fullname"
                placeholder="Your Name"
                required
                />
            </div>
            <div className="form-group">
                <label>Contry</label>
                <Select options={options} value={value} onChange={changeHandler} />
            </div>

            <div className="form-group">
                <label>Email Address</label>
                <input 
                type="email"
                name="email"
                placeholder="Email Address"
                required
                />
            </div>

            <div className="form-group">
                <label>Phone Number</label>
                <input 
                type="text"
                name="phone"
                placeholder="Phone Number"
                required
                />
            </div>

            <div className="form-group">
                <label>Bio</label>
                <textarea
                name="subject"
                placeholder="Tell us about yourself"
                required
                ></textarea>
            </div>

            <div className="form-group">
                <label>Cover Letter</label>
                <textarea 
                name="message"
                placeholder="Why do you want to be part of Vindex Team?"
                required
                >
                </textarea>
            </div>

            <div className="form-group">
            <label>Identity Verification Document</label>
                 <FileUploader handleChange={handleChange} name="file" types={fileTypes} required />
            </div>
            <div className="form-group">
                <button className="btn-default btn-large">Submit Now</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ApplyForm;
