import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <> 
            <meta charSet="utf-8" />
            <title>{title} | Vindex Group</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="keywords" content="Vindex, Group, Call Center, Call-Center, Telemarketing, Kundenservice, customer service, Leads generieren, Marketing, Web Development, App-Entwicklung, Web Entwicklung" />
            <meta name="author" content="Ndricim Beqiri" />
            <meta
                name="description"
                content="Wir liefern professionelle Human Experience für die heutigen digitalen Anforderungen. From e-commerce to fintech, we work with you to achieve rapid growth and generate leads. Discover how we can deliver the results you need, fast and at scale."
                />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


