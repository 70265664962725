import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Schritt-1",
        title: "Präsentieren",
        description: "Zu Beginn wird unser Team alles über die Bedürfnisse der Kunden erfahren und den gesamten Hintergrund der Kundenaktivitäten verstehen. Anschließend werden wir auch unser Unternehmen und die von uns angebotenen Lösungen vorstellen und versuchen, klarzumachen, was wir tun können, um sie zu präsentieren, die besten Lösungen für Ihre Bedürfnisse.",
        image: "timeline-01",
        workingStep: [
        ]
    },
    {
        id: "2",
        date: "Schritt-2",
        title: "Kommunikationsphase",
        description: "Die Kommunikationsphase kann nur aus Gründen der Klarheit der Zusammenarbeit zwischen 1 Woche und 8 Wochen dauern. In der Kommunikationsphase werden wir unser Bestes geben, um detaillierte Informationen über den Plan, die Strategie und die Dienstleistungen zu geben, um die der Kunde gebeten hat oder nach weiteren Informationen sucht.",
        image: "timeline-02",
        workingStep: [
        ]
    },
    {
        id: "3",
        date: "Schritt-3",
        title: "Vertragsabschluss für Lösungen",
        description: "Nachdem beide Seiten den Abschluss der neuen Reise akzeptiert haben, um die Kundenziele und -vorgaben für alles zu erreichen, was sie brauchen, muss unser Team einen Kooperationsvertrag erstellen, der dem entspricht, was wir vereinbart haben.",
        image: "timeline-03",
        workingStep: [
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/timeline/${data.image}.png`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
