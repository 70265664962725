import React from 'react';
import VideoTwo from '../video/VideoTwo';
import { FiCheck } from "react-icons/fi";


const PopupData = [
    {
        id: "01",
        image: "./images/bg/bg-image-4.jpg",
        popupLink: [
            '',
        ],
    }
]

const AboutFour = ({image}) => {
    return (
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        {PopupData.map((item) => (
                            <div className="video-btn" key={item.id}>
                                <VideoTwo imagename={`${image}`} galleryItem={item} />
                            </div>
                        ))}
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Werte, um unsere Ziele zu schaffen</h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Leichter Erfolg</h4>
                                            <p className="text">Ihr Erfolg ist unser Erfolg - wir möchten Ihre Fähigkeiten während Ihres Aufenthalts bei uns ausbauen. Jede Leistung Ihrerseits ist es uns wert.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Gruppenarbeit</h4>
                                            <p className="text">Zusammenarbeiten und gut mit Teams arbeiten - Wie das alte Sprichwort sagt, gibt es mich nicht im TEAM. Wir glauben, dass wir alle schneller aufsteigen können, wenn wir uns umeinander kümmern, anstatt einzeln ohne Unterstützung aufzusteigen.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">Lösungen</h4>
                                            <p className="text">Lösungen schaffen, keine Ausreden – Wir verwenden gerne den Begriff „vorwärtsgehen“, weil er das Verständnis impliziert, dass wir hier sind, um an unseren Fehlern zu wachsen, anstatt uns in ihnen zu suhlen.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFour
