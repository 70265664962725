import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Kontakt Telefonnummer</h4>
                                        <p><a href="tel:+383 48 123 121">+383 48 123 121</a></p>
                                        <p><a href="tel:+383 49 815 959">+383 49 815 959</a></p>
                                        <p><a href="tel:+49 176 31073063">+49 176 31073063</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Unsere E-Mail-Adresse</h4>
                                        <p><a href="mailto:ndricimbeqiri@vindex.group">ndricimbeqiri@vindex.group</a></p>
                                        <p><a href="mailto:violramadani@vindex.group">violramadani@vindex.group</a></p>
                                        <p><a href="mailto:sh.heta@vindex.group">sh.heta@vindex.group</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Unsere Position</h4>
                                        <p>Rr. 12 Qershori, Kompleksi Tresor<br /> Hyrja e katit te dyte</p>
                                        <p>Objekti mbi Titan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div>
            </div>
        </>
    )
}
export default ContactOne;